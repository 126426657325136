import { useEffect } from 'react'
import socketIOClient from 'socket.io-client'
import { baseURL } from 'store/constant'
import { dispatch } from 'store'
import { handleDatagridSocketCells, handleDatagridSocketGridData } from '../tailwindui/pages/datagrid/api'
var msgs = []
let socket
export default () => {
    const init = async () => {
        socket = socketIOClient.connect(baseURL)

        socket.on('connect', () => {
            dispatch({ type: 'SET_SOCKET_ID', payload: socket.id })
            const randomInt = Math.random().toString(36).substring(7)
            const roomId = `${randomInt}-${socket.id}`
            dispatch({ type: 'SET_ROOM_ID', payload: roomId })
            socket.on(roomId, (data) => {
                msgs.push(data)
                console.log('data', data)
                dispatch({ type: 'SET_SOCKET_DATA', payload: data })
                handleDatagridSocketCells(data)
            })
        })
    }
    useEffect(() => {
        init()
        const cl = setInterval(() => {
            if (msgs.length > 0) {
                const msgLength = msgs.length
                // get all message till length and remove them from array
                const getmsgs = msgs.slice(0, msgLength)
                msgs = msgs.slice(msgLength)
                handleDatagridSocketGridData(getmsgs)
            }
        }, 1000)
        return () => {
            if (socket) {
                socket.disconnect()
                dispatch({ type: 'CLEANUP_SOCKET' })
            }
            clearInterval(cl)
        }
    }, [])

    return {
        socket
    }
}
