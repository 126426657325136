/* eslint-disable no-case-declarations */
import { getHighlightedCells, filterRows, titleCase } from '../../tailwindui/pages/datagrid/utils'
const sortCell = (cells, newCells) => {
    newCells.forEach((cell) => {
        const findCell = cells.find((c) => c._id === cell._id)
        if (!findCell) {
            cells = [...cells, cell]
        }
    })
    // sor cell by cell.index
    cells.sort((a, b) => a.index - b.index)
    return cells
}
const handleeAiCellUpdate = (cells, newCells) => {
    const c = cells.map((cell) => {
        const findCell = newCells.find((c) => c.uiId === cell.uiId)
        if (findCell) {
            return findCell
        }
        return cell
    })
    return [...new Set(c)]
}
const handleDeleteAiCell = (cells, toDeleteCells) => {
    const toDeleteUiids = toDeleteCells.map((cell) => cell._id)
    const c = cells.filter((cell) => !toDeleteUiids.includes(cell._id))
    return [...new Set(c)]
}
const handleGridData = (state, payload) => {
    let { filteredRows, rows } = state
    payload.forEach((item) => {
        const { rowId, cellId, value } = item
        rows = rows.map((row) => {
            if (row._id === rowId) {
                return {
                    ...row,
                    [cellId]: value
                }
            }
            return row
        })
    })
    filteredRows = filteredRows.map((row) => {
        const findRow = rows.find((r) => r._id === row._id)
        if (findRow) {
            return findRow
        }
        return row
    })
    return {
        rows,
        filteredRows
    }
}
const setHeadings = (headings, { scrollLeft, maxRight }) => {
    let totalWidth = 80
    const windowWidth = window.innerWidth + scrollLeft
    headings = headings.map((heading) => ({
        ...heading,
        label: titleCase(heading.label)
    }))

    headings = headings.sort((a, b) => a.index - b.index)
    const h = headings.map((heading) => {
        if (heading.hidden) return heading
        const width = heading.width ?? 240
        const isVisible = totalWidth >= scrollLeft - width && totalWidth < windowWidth
        totalWidth += width // Accumulate the width for the next column
        return { ...heading, uiHidden: !isVisible, left: totalWidth - width }
    })
    return { headings: [...h], maxRight: totalWidth, scrolled: scrollLeft }
}

const initialState = {
    width: 240,
    height: 40,
    table: null,
    headings: [],
    rows: [],
    filteredRows: [],
    activeCellId: null,
    activeRow: null,
    panelTitle: null,
    selectedRows: [],
    expandCell: null,
    expandRow: null,
    clickOutsideTime: null,
    popover: null,
    editCellTitle: null,
    focusCell: null,
    openChat: false,
    disabled: true,
    creatingNewWb: false,
    messages: [
        {
            message: 'Hello, how can I help you?',
            sender: 'bot'
        }
    ],
    log: null,
    loadingRows: false,
    connectionDialog: null,
    filters: [],
    search: '',
    doSearch: false,
    highlightedCells: [],
    rowFilter: {
        startingRow: 0,
        endingRow: 0
    },
    duplicateChecker: {
        columns: [],
        rows: [],
        open: false,
        gridIds: []
    },
    csv: {
        uploading: false,
        data: [],
        progress: 0,
        mappedCols: [],
        tableId: null
    },
    isCategoryModalOpen: false,
    cell: null,
    cellNameTransfer: null,
    loaded: false,
    loading: false,
    filteredRowsCount: 0,
    cells: [],
    maxRight: 0,
    scrolled: 0,
    cellPopover: null
}
const datagridReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case 'RUN_ON_SELECTED_ROWS':
            return {
                ...state,
                filteredRows: state.filteredRows.map((row) => {
                    const payloadRow = payload.find((r) => r.rowId === row._id)
                    if (payloadRow && typeof payloadRow === 'object') {
                        payloadRow.cells.map((cellId) => {
                            row[cellId] = 'Queued...'
                        })
                        return {
                            ...row
                        }
                    }
                    return row
                })
            }
        case 'SET_ROWS_LOADED':
            return {
                ...state,
                loadingRows: payload
            }
        case 'SET_EXPAND_CELL':
            return {
                ...state,
                cellPopover: payload
            }
        case 'UPDATE_GRID_DATA':
            return {
                ...state,
                ...handleGridData(state, payload)
            }
        case 'SET_LOADING_DG':
            return {
                ...state,
                loading: payload
            }
        case 'SET_TABLE_LOADED':
            return {
                ...state,
                loaded: payload
            }
        case 'SET_TABLE_DG_CLEANUP':
            return {
                ...initialState
            }
        case 'SET_DISABLE':
            return {
                ...state,
                disabled: payload
            }
        case 'CREATE_CELL_BY_AI_CHAT':
            return {
                ...state,
                cellNameTransfer: payload
            }
        case 'SET_CELL':
            return {
                ...state,
                cell: payload
            }
        case 'OPEN_CATEGORY_MODAL':
            return {
                ...state,
                isCategoryModalOpen: true,
                cell: payload
            }
        case 'CLOSE_CATEGORY_MODAL':
            return {
                ...state,
                isCategoryModalOpen: false,
                cell: null
            }
        case 'SET_IMPORT_CSV_PROGRESS':
            return {
                ...state,
                csv: {
                    ...state.csv,
                    progress: payload
                }
            }
        case 'SET_IMPORT_CSV_START':
            return {
                ...state,
                csv: {
                    ...state.csv,
                    uploading: true
                }
            }
        case 'UPLOAD_CSV_DG':
            return {
                ...state,
                csv: {
                    ...state.csv,
                    uploading: false,
                    data: payload.data,
                    mappedCols: payload.mappedCols,
                    tableId: payload.tableId
                }
            }
        case 'TOGGLE_DEDUPLICATER':
            return {
                ...state,
                duplicateChecker: payload
            }
        case 'SET_ROW_FILTER':
            const filteredRows4 = filterRows(state.rows, state.filters, payload)
            return {
                ...state,
                rowFilter: payload,
                filteredRows: [...filteredRows4],
                filteredRowsCount: filteredRows4.length
            }
        case 'SELECT_MULTIPLE_CELL':
            return {
                ...state,
                highlightedCells: getHighlightedCells(state.highlightedCells, payload, state.rows)
            }
        case 'DO_SEARCH_DG':
            return {
                ...state,
                doSearch: payload
            }
        case 'SEARCH_DG':
            return {
                ...state,
                search: payload
            }
        case 'setCreatingNewWb':
            return {
                ...state,
                creatingNewWb: payload
            }
        case 'SET_FILTERS_DG':
            const filteredRows1 = filterRows(state.rows, payload, state.rowFilter)
            return {
                ...state,
                filters: payload,
                filteredRows: [...filteredRows1],
                filteredRowsCount: filteredRows1.length
            }
        case 'OPEN_CONNECTION_DIALOG':
            return {
                ...state,
                connectionDialog: payload
            }
        case 'CLOSE_CONNECTION_DIALOG':
            return {
                ...state,
                connectionDialog: null
            }
        case 'OPEN_CELL_RUN_LOG':
            return {
                ...state,
                log: payload
            }
        case 'CLOSE_CELL_RUN_LOG':
            return {
                ...state,
                log: null
            }
        case 'SET_MESSAGES_DG':
            return {
                ...state,
                messages: payload
            }
        case 'DELETE_CELLS_DG':
            const header9 = setHeadings(handleDeleteAiCell(state.headings, payload), {
                scrollLeft: state.scrolled,
                maxRight: state.maxRight
            })
            return {
                ...state,
                headings: header9.headings,
                maxRight: header9.maxRight,
                scrolled: header9.scrolled
            }
        case 'ADD_MESSAGE_DG':
            return {
                ...state,
                messages: [...state.messages, payload]
            }
        case 'CLOSE_CHAT_DG':
            return {
                ...state,
                openChat: false
            }
        case 'START_CHAT_DG':
            return {
                ...state,
                openChat: true
            }
        case 'SET_FOCUS_CELL_DG':
            return {
                ...state,
                focusCell: payload
            }
        case 'SORT_ROWS':
            return {
                ...state,
                rows: [...state.rows].sort((a, b) => {
                    if (payload.datatype === 'number') {
                        let aValue = a[payload.headerID] ?? 0
                        let bValue = b[payload.headerID] ?? 0
                        aValue = parseFloat(aValue)
                        bValue = parseFloat(bValue)
                        if (payload.order === 'ASC') {
                            return aValue > bValue ? 1 : -1
                        }
                        return aValue < bValue ? 1 : -1
                    } else if (payload.datatype === 'date') {
                        const aValue = new Date(a[payload.headerID]) ?? 0
                        const bValue = new Date(b[payload.headerID]) ?? 0
                        if (payload.order === 'ASC') {
                            return aValue > bValue ? 1 : -1
                        }
                        return aValue < bValue ? 1 : -1
                    } else {
                        let aValue = a[payload.headerID] ?? ''
                        let bValue = b[payload.headerID] ?? ''
                        if (typeof aValue !== 'string') {
                            aValue = JSON.stringify(aValue)
                        }
                        if (typeof bValue !== 'string') {
                            bValue = JSON.stringify(bValue)
                        }
                        if (payload.order === 'ASC') {
                            return aValue.localeCompare(bValue)
                        }
                        return bValue.localeCompare(aValue)
                    }
                })
            }
        case 'START_RENAME_HEADING_DG':
            return {
                ...state,
                editCellTitle: payload
            }

        case 'CLICK_OUTSIDE':
            return {
                ...state,
                clickOutsideTime: payload
            }

        case 'SET_HEIGHT_DG':
            return {
                ...state,
                height: payload
            }
        case 'SET_EXPAND_ROW':
            return {
                ...state,
                expandRow: payload ? state.rows.find((row) => row._id === payload) : null
            }
        case 'EXPAND_CELL':
            return {
                ...state,
                expandCell: payload
            }
        case 'ADD_ROWS_DG':
            return {
                ...state,
                rows: [...state.rows, ...payload]
            }
        case 'RUN_ON_SELECTED_ROW_START':
            return {
                ...state,
                filteredRows: state.filteredRows.map((row) => {
                    if ((payload.selectedRows ?? []).includes(row._id)) {
                        return {
                            ...row,
                            [payload.cellId]: 'Queued...'
                        }
                    }
                    return row
                })
            }
        case 'SET_SELECTED_ROWS':
            return {
                ...state,
                selectedRows: payload
            }
        case 'ADD_AI_GENERATED_CELLS':
            let headings8 = [...new Set(sortCell(state.headings, payload))].map((heading) => {
                if (heading.uiId === payload) {
                    return { ...heading, hidden: !heading.hidden }
                }
                return heading
            })
            const header8 = setHeadings(headings8, { scrollLeft: state.scrolled, maxRight: state.maxRight })
            return {
                ...state,
                headings: header8.headings,
                maxRight: header8.maxRight,
                scrolled: header8.scrolled
            }
        case 'SET_SELECTED_ROW_DG':
            return {
                ...state,
                selectedRows: [
                    ...new Set(
                        payload.isChecked
                            ? [...state.selectedRows, payload.rowId]
                            : state.selectedRows.filter((rowId) => rowId !== payload.rowId)
                    )
                ]
            }
        case 'HIDE_HEADING_DG':
            let headings7 = state.headings.map((heading) => {
                if (heading.uiId === payload) {
                    return { ...heading, hidden: !heading.hidden }
                }
                return heading
            })
            const header7 = setHeadings(headings7, { scrollLeft: state.scrolled, maxRight: state.maxRight })
            return {
                ...state,
                headings: header7.headings,
                maxRight: header7.maxRight,
                scrolled: header7.scrolled
            }
        case 'REORDER_HEADING_DG':
            const header4 = setHeadings(payload, { scrollLeft: state.scrolled, maxRight: state.maxRight })
            return {
                ...state,
                headings: header4.headings,
                maxRight: header4.maxRight,
                scrolled: header4.scrolled
            }
        case 'SET_DEFAULT_VALUES_ROW':
            return {
                ...state,
                rows: [
                    ...state.rows.map((row) => {
                        return {
                            ...row,
                            [payload.key]: row[payload.key] ?? payload.value
                        }
                    })
                ]
            }
        case 'SET_TABLE_DG':
            console.log('SET_TABLE_DG', payload)
            return {
                ...state,
                table: payload,
                disabled: state.session?.user?.role !== 'admin' && state.session?.user?._id !== payload?.table?.userId,
                filters: payload?.filter ?? []
            }
        case 'SET_DRAWER_OPEN':
            return {
                ...state,
                isDrawerOpen: payload
            }
        case 'EDIT_HEADING_DG':
            return {
                ...state,
                // isDrawerOpen: !!payload,
                activeHeadingId: payload
                // panelTitle: 'Edit Column'
            }
        case 'SET_HEADINGS_DG':
            const header = setHeadings(payload, { scrollLeft: state.scrolled, maxRight: state.maxRight })
            return {
                ...state,
                headings: header.headings,
                maxRight: header.maxRight,
                scrolled: header.scrolled
            }
        case 'HIDE_CELLS_ON_SCROLL':
            const header1 = setHeadings(state.headings, payload)
            return {
                ...state,
                headings: header1.headings,
                maxRight: header1.maxRight,
                scrolled: header1.scrolled
            }
        case 'ADD_HEADING_DG':
            const header2 = setHeadings([...state.headings, payload], { scrollLeft: state.scrolled, maxRight: state.maxRight })
            return {
                ...state,
                headings: [...header2.headings],
                maxRight: header2.maxRight,
                scrolled: header2.scrolled
            }
        case 'DELETE_HEADING_DG':
            const headings = state.headings
                .filter((heading) => heading.uiId !== payload.uiId)
                .map((heading) => {
                    if (heading.index > payload.index) {
                        return { ...heading, index: heading.index - 1 }
                    }
                    return heading
                })
            const header3 = setHeadings([...headings], { scrollLeft: state.scrolled, maxRight: state.maxRight })
            return {
                ...state,
                headings: [...header3.headings],
                maxRight: header3.maxRight,
                scrolled: header3.scrolled
            }
        case 'UPDATE_HEADING_DG':
            let newHeadings = state.headings
            const findHeading = newHeadings.find((heading) => heading.uiId === payload.uiId)
            if (findHeading) {
                newHeadings = state.headings.map((heading) => {
                    if (heading.uiId === payload.uiId) {
                        return payload
                    }
                    return heading
                })
            } else {
                newHeadings = [...state.headings, payload]
            }
            const header5 = setHeadings([...newHeadings], { scrollLeft: state.scrolled, maxRight: state.maxRight })
            return {
                ...state,
                headings: [...header5.headings],
                maxRight: header5.maxRight,
                scrolled: header5.scrolled
            }

        case 'UPDATE_CELLS_DG':
            const header6 = setHeadings(handleeAiCellUpdate(state.headings, payload), {
                scrollLeft: state.scrolled,
                maxRight: state.maxRight
            })
            return {
                ...state,
                headings: [...header6.headings],
                maxRight: header6.maxRight,
                scrolled: header6.scrolled
            }

        case 'SET_SELECTED_CELL_DG':
            return {
                ...state,
                activeCellId: payload,
                popover: payload ? state.popover : null
            }
        case 'SET_ANCHOR_EL_DG':
            return {
                ...state,
                popover: payload
            }
        case 'UPDATE_CELL_DATA_DG':
            return {
                ...state,
                rows: state.rows.map((row) => {
                    if (row.id === payload.rowId) {
                        return {
                            ...row,
                            [payload.cellId]: payload.value
                        }
                    }
                    return row
                }),
                filteredRows: state.filteredRows.map((row) => {
                    if (row.id === payload.rowId) {
                        return {
                            ...row,
                            [payload.cellId]: payload.value
                        }
                    }
                    return row
                })
            }
        case 'SET_ROWS_DG':
            const filteredRows = filterRows(payload, state.filters, state.rowFilter)
            return {
                ...state,
                filteredRows,
                rows: payload,
                filteredRowsCount: filteredRows.length
            }
        case 'ADD_ROW_DG':
            const filteredRows2 = filterRows([...state.rows, payload], state.filters, state.rowFilter)
            return {
                ...state,
                rows: [...state.rows, payload],
                filteredRows: filteredRows2,
                filteredRowsCount: filteredRows2.length
            }
        case 'DELETE_ROW_DG':
            return {
                ...state,
                rows: state.rows.filter((row) => row._id !== payload.rowId)
            }
        case 'DELETE_ROWS_DG':
            const rows1 = state.rows.filter((row) => !payload.includes(row._id))
            const filteredRows5 = filterRows(rows1, state.filters, state.rowFilter)

            return {
                ...state,
                rows: rows1,
                filteredRows: filteredRows5,
                filteredRowsCount: filteredRows5.length,

                selectedRows: []
            }
        case 'UPDATE_ROW_DG':
            return {
                ...state,
                rows: state.rows.map((row) => {
                    if (row._id === payload._id) {
                        return payload
                    }
                    return row
                })
            }
        case 'UPDATE_ROWS':
            return {
                ...state,
                rows: state.rows.map((row) => {
                    if (payload.includes(row._id)) {
                        return {
                            ...row,
                            ...payload
                        }
                    }
                    return row
                })
            }
        case 'UPDATE_COLUMN':
            return {
                ...state,
                rows: state.rows.map((row) => {
                    return {
                        ...row,
                        [payload.columnId]: payload.column
                    }
                })
            }
    }
    return state
}
export default datagridReducer
