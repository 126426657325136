export const initialState = {
    tools: [],
    categories: [],
    tool: null,
    category: null,
    connections: []
}

const toolsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_CONNECTION':
            return {
                ...state,
                connections: [...state.connections, action.payload]
            }
        case 'SET_CONNECTIONS':
            return {
                ...state,
                connections: action.payload
            }
        case 'SET_TOOL':
            return {
                ...state,
                tool: action.payload
            }
        case 'SET_CATEGORIES':
            return {
                ...state,
                categories: action.payload
            }
        case 'ADD_TOOL':
            return {
                ...state,
                tools: [...state.tools, action.payload]
            }
        case 'SET_TOOLS':
            return {
                ...state,
                tools: action.payload ?? []
            }
        case 'UPDATE_TOOL':
            return {
                ...state,
                tools: state.tools.map((tool) => (tool._id === action.payload._id ? action.payload : tool))
            }
        case 'DELETE_TOOL':
            return {
                ...state,
                tools: state.tools.filter((tool) => tool._id !== action.payload)
            }
        case 'SET_CATEGORY':
            return {
                ...state,
                category: action.payload
            }
        case 'UPDATE_CATEGORY':
            return {
                ...state,
                categories: state.categories.map((category) => (category._id === action.payload._id ? action.payload : category))
            }
        case 'DELETE_CATEGORY':
            return {
                ...state,
                categories: state.categories.filter((category) => category._id !== action.payload)
            }
        case 'ADD_CATEGORY':
            return {
                ...state,
                categories: [...state.categories, action.payload]
            }
        default:
            return state
    }
}

export default toolsReducer
