import Page from '../../components/Page'
import { LoginForm } from '../../components/auth/login'
import SocialAuth from './SocialAuth'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

export default function Login() {
    const navigate = useNavigate()
    const search = window.location.search
    const params = new URLSearchParams(search)
    const redirectAfterLogin = params.get('redirectAfterLogin')

    const afterLogin = () => {
        if (redirectAfterLogin) {
            window.location.href = redirectAfterLogin
        } else {
            navigate('/dashboard')
        }
    }

    return (
        <Page title='Login'>
            <div className='flex flex-col items-center justify-center min-h-screen py-12 px-4 sm:px-6 lg:px-8'>
                <div className='max-w-md w-full space-y-8'>
                    <img className='mx-auto h-12 w-auto' src='/logo/logo.svg' alt='Workflow' />
                </div>
                <div className='max-w-md w-full space-y-8'>
                    <h3 className='text-2xl font-medium leading-6 text-gray-900 text-center mt-8'>Sign in to your account</h3>

                    <LoginForm afterLogin={afterLogin} />
                    <SocialAuth afterLogin={afterLogin} />
                    <p className='mt-2 text-center text-sm text-gray-600'>
                        Don’t have an account?{' '}
                        <Link variant='subtitle2' to='/auth/register'>
                            Get started
                        </Link>
                    </p>
                </div>
            </div>
        </Page>
    )
}
