import client from './client'

const getCategories = () => client.get('/category')

const getCategory = (id) => client.get(`/category/${id}`)

const createNewCategory = (body) => client.post(`/category`, body)

const updateCategory = (id, body) => client.put(`/category/${id}`, body)

const deleteCategory = (id) => client.delete(`/category/${id}`)

export default {
    getCategory,
    getCategories,
    createNewCategory,
    updateCategory,
    deleteCategory
}
