import client from './client'

const getAllUsertable = (isTemplates) => client.get(`/usertable`, { params: { isTemplates } })
const getAllTable = (pagination) => client.get(`/usertable`, { params: pagination })
const getAllApps = () => client.get(`/usertable`, { params: { koalaTable: true } })

const getUsertable = (id, rowId) => client.get(rowId ? `/usertable/${id}?rowId=${rowId}` : `/usertable/${id}`)
const gettable = (id) => client.get(`/usertable/${id}`)

const createNewUsertable = (body) => client.post(`/usertable`, body)
const getschema = (body) => client.post(`/usertable/getschema`, body)

const updateUsertable = (id, body) => client.put(`/usertable/${id}`, body)

const deleteUsertable = (id) => client.delete(`/usertable/${id}`)
const bulkRun = (body) => client.post(`/bots/bulkRun`, body)

export default {
    getTemplate: (id) => client.get(`/usertable/${id}/template`),
    getCells: (id) => client.get(`/usertable/${id}/cells`),
    getRows: (id, pagination) => client.get(`/usertable/${id}/rows`, { params: pagination }),
    getTemplates: () => client.get(`/usertable?isTemplates=true`),
    createSecret: (id) => client.post(`/usertable/${id}/regenerate-secret`),
    gettable,
    getAllUsertable,
    getAllTable,
    getUsertable,
    getUsertableForPlayground: (id) => client.get(`/usertable/${id}/playground`),
    createNewUsertable,
    updateUsertable,
    deleteUsertable,
    bulkRun,
    getschema,
    getAllApps,
    getAiColumnData: (id, payload) => client.post(`/usertable/${id}/aiColumnData`, payload),
    getLogs: (tableId, cellId, rowId, onlyFirst) =>
        client.get(`/usertable/${tableId}/cell/${cellId}/row/${rowId}/logs?onlyFirst=${onlyFirst}`),
    getTool: (payload) => client.post(`/usertable/tool`, payload),
    updateGlobalParams: (id, payload) => client.post(`/usertable/${id}/updateGlobalParams`, payload),
    markSample: (id, payload) => client.post(`/usertable/${id}/markSample`, payload),
    addWB: (id) => client.post(`/usertable/${id}/addWB`),
    cloneWb: (id) => client.post(`/usertable/${id}/cloneWb`),
    startPageBuilder: () => client.post(`/usertable/startPageBuilder`),
    renameTable: (id, payload) => client.post(`/usertable/${id}/renameTable`, payload),
    createNewWb: (payload) => client.post(`/usertable/createNewWb`, payload),
    renameCell: (id, payload) => client.post(`/usertable/${id}/renameCell`, payload),
    saveCell: (id, payload) => client.post(`/usertable/${id}/saveCell`, payload),
    deleteCell: (id, payload) => client.post(`/usertable/${id}/deleteCell`, payload),
    duplicateWb: (id) => client.post(`/usertable/${id}/duplicateWb`),
    runWb: (id, payload) => client.post(`/usertable/${id}/runWb`, payload),
    getUserTable: (id) => client.get(`/users/${id}/tables`),
    stopRunAll: (id, payload) => client.post(`/usertable/${id}/stopRunAll`, payload),
    getWBRun: (id) => client.get(`/usertable/${id}/getWBRun`),
    checkWBRun: (id, payload) => client.post(`/usertable/${id}/checkWBRun`, payload),
    getRowData: (id, payload) => client.post(`/usertable/${id}/getRowData`, payload),
    runPlayground: (id, payload) => client.post(`/usertable/${id}/runPlayground`, payload),
    saveAsDocument: (id, payload) => client.post(`/documents/${id}/saveAsDocument`, payload),
    updateRow: (id, payload) => client.post(`/usertable/${id}/updateRow`, payload),
    getSuggestions: (id, payload) => client.post(`/usertable/${id}/getSuggestions`, payload),
    shareWb: (payload) => client.post(`/shareWorkbook`, payload),
    getShareStatus: (payload) => client.post(`/shareWorkbook/status`, payload),
    getSharedWbPublic: (payload) => client.post(`/shareWorkbook/getSharedWbPublic`, payload),
    getSharedWb: (payload) => client.post(`/shareWorkbook/getSharedWb`, payload),
    cloneSharedWb: (id) => client.post(`/shareWorkbook/cloneSharedWb`, { id }),
    getContentWorkbook: () => client.get(`/shareWorkbook/contentWorkbook`),
    runKoalaTable: (id, payload) => client.post(`/usertable/${id}/runKoalaTable`, payload),
    getApps: () => client.get(`/usertable/getApps`),
    generateContent: (id, payload) => client.post(`/shareWorkbook/${id}/generateContent`, payload),
    uploadImg: (body) => client.post(`/shareWorkbook/uploadImg`, body),
    getUserDocs: (id) => client.get(`/users/${id}/documents`),
    getUser: (id) => client.get(`/users/${id}`),
    findRow: (id) => client.get(`/usertable/${id}/findrow`),
    addRow: (id, payload) => client.post(`/usertable/${id}/addRow`, payload),
    getLatestRuns: () => client.get(`/usertable/latestRuns`),
    uploadThumbnail: (id, body) => client.post(`/usertable/${id}/uploadThumbnail`, body),
    importCsv: (id, body) => client.post(`/usertable/${id}/importCsv`, body),
    getRow: (id, rowId) => client.get(`/usertable/${id}/row/${rowId}`),
    importAppData: (body) => client.post(`/apps/importAppData`, body),
    saveOrder: (id, payload) => client.post(`/usertable/${id}/saveOrder`, payload),
    runOnRows: (id, payload) => client.post(`/usertable/${id}/runOnRows`, payload),
    getCredits: (id) => client.get(`/credits/${id}`),
    updateFilterDb: (id, payload) => client.post(`/usertable/${id}/updateFilterDb`, payload)
}
