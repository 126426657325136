import { dispatch, state } from 'store'
import usertableApi from 'api/usertable'
import apps from 'api/apps'
import { checkForRunAiCellOnRowUpdate, titleCase } from './utils'
import { isArray } from 'lodash'
const toast = (message, type) => {
    dispatch({ type: 'TOAST', payload: { message, type } })
}
const setLoading = (loading) => {
    dispatch({ type: 'SET_LOADING_DG', payload: loading })
}
const setCreatingNewWb = (loading) => {
    dispatch({ type: 'setCreatingNewWb', payload: loading })
}
const getTable = async (id) => {
    try {
        const st = state()
        const {
            datagrid: { csv }
        } = st

        const userId = st.user?._id
        dispatch({ type: 'SET_TABLE_LOADED', payload: false })
        const result = await usertableApi.gettable(id)
        if (result.status && result.data) {
            if (result.data) {
                dispatch({ type: 'SET_DISABLE', payload: result.data.userId !== userId })

                dispatch({ type: 'SET_TABLE_DG', payload: result.data })
                if (!(result.data.importStatus === 'Completed' || result.data.importStatus === 'pending')) {
                    if (csv && csv.uploading === true && csv.tableId === id && csv.progress < 100) {
                        // check for csv import status
                    } else {
                        setTimeout(() => {
                            getTable(id)
                        }, 5000)
                    }
                } else {
                    getCells(id)
                    getRows(id)
                }
            }
        } else {
            toast(`Failed to retrieve Table`, 'error')
        }
    } catch (error) {
        console.log(error)
        toast(`Failed to retrieve Table`, 'error')
    } finally {
        dispatch({ type: 'SET_TABLE_LOADED', payload: true })
    }
}
const getCells = async (id) => {
    try {
        dispatch({ type: 'SET_CELLS_LOADED', payload: true })
        const result = await usertableApi.getCells(id)
        if (result.status && result.data) {
            const c = result.data.map((cell) => ({ ...cell, label: titleCase(cell.label) }))
            dispatch({ type: 'SET_CELLS_FOR_DG', payload: c }) // for ai cell edit different from headings
            dispatch({ type: 'SET_HEADINGS_DG', payload: c })
        } else {
            toast(`Failed to retrieve Table`, 'error')
        }
    } catch (error) {
        console.log(error)
        toast(`Failed to retrieve Table`, 'error')
    } finally {
        dispatch({ type: 'SET_CELLS_LOADED', payload: false })
    }
}

const getRows = async (id) => {
    try {
        dispatch({ type: 'SET_ROWS_LOADED', payload: true })
        const pagination = { page: 0, limit: 20, sortBy: 'createdAt:asc' }
        const result = await usertableApi.getRows(id, pagination)
        if (result.status && result.data && result.data.results) {
            const rws = result.data.results.map((row) => ({ ...row, id: row._id.toString() }))
            dispatch({ type: 'SET_ROWS_DG', payload: rws })
            if (result.data.totalResults > 20) {
                const pagination1 = { page: 0, limit: result.data.totalResults, sortBy: 'createdAt:asc' }
                const result1 = await usertableApi.getRows(id, pagination1)
                if (result1.status && result1.data && result1.data.results) {
                    const rws = result1.data.results.map((row) => ({ ...row, id: row._id.toString() }))
                    dispatch({ type: 'SET_ROWS_DG', payload: rws })
                }
            }
        } else {
            toast(`Failed to retrieve Table`, 'error')
        }
    } catch (error) {
        console.log(error)
        toast(`Failed to retrieve Table`, 'error')
    } finally {
        dispatch({ type: 'SET_ROWS_LOADED', payload: false })
    }
}
const saveRow = async (row) => {
    try {
        const st = state()
        const {
            datagrid: {
                table: { _id }
            },
            socket: { roomId }
        } = st
        setLoading(true)
        const result = await usertableApi.updateRow(_id, { ...row, roomId })
        if (result && result.data && result?.data?.status) {
            dispatch({ type: 'UPDATE_ROW_DG', payload: result?.data })
            runAiCellOnRowUpdate(result?.data)
        } else {
            toast(`Failed to update row`, 'error')
        }
        setLoading(false)
    } catch (error) {
        console.log('error', error)
        toast(`Failed to update row`, 'error')
        setLoading(false)
    }
}
const updateFilterDb = async (payload) => {
    try {
        const st = state()
        const {
            datagrid: {
                table: { _id }
            }
        } = st
        usertableApi.updateFilterDb(_id, payload)
    } catch (error) {
        console.log('error', error)
    }
}
const handleRename = async (item) => {
    try {
        setLoading(true)
        const result = await usertableApi.updateUsertable(item._id, item)
        if (result && result.data) {
            toast('Table updated successfully', 'success')
            dispatch({ type: 'SET_TABLE_DG', payload: result.data })
        }
        setLoading(false)
    } catch (error) {
        const errorData = error?.response?.data?.message ?? `${error?.response?.statusText}`
        toast(`Failed to update Table: ${errorData ?? ''}`, 'error')
        setLoading(false)
    }
}
const saveCell = async (payload) => {
    const st = state()
    const {
        session: { user },
        datagrid: { table },
        socket: { roomId }
    } = st
    const userId = user?._id
    const disabled = userId !== table.userId
    if (disabled) {
        toast('You are not authorized to edit this table', 'error')
        return
    }
    try {
        const id = table._id
        setLoading(payload.uiId)
        payload.roomId = roomId
        const result = await usertableApi.saveCell(id, payload)
        if (result.status && result.data) {
            dispatch({ type: 'SET_CELLS', payload: result.data })
            dispatch({ type: 'SET_HEADINGS_DG', payload: result.data })
            let c = result.data
            c = c.map((cell) => ({ ...cell, label: titleCase(cell.label) }))
            dispatch({ type: 'SET_CELLS_FOR_DG', payload: c })
            setLoading(false)
            console.log('aaaaaa')
            toast('Saved', 'success')
            return result.data
        } else {
            toast(`Failed to save`, 'error')
        }
        setLoading(false)
    } catch (error) {
        console.log({ error })
        toast(`Failed to save`, 'error')
        setLoading(false)
    }
}
const saveOrder = async (items) => {
    try {
        const st = state()
        const {
            session: { user },
            datagrid: { table }
        } = st
        const id = table?._id
        const disabled = user?._id !== table.userId
        if (disabled) {
            toast('You are not authorized to edit this table', 'error')
            return
        }
        setLoading(true)
        items = items.map((item) => ({ _id: item._id, index: item.index }))
        usertableApi.saveOrder(id, { items })
    } catch (error) {
        console.log({ error })
        toast(`Failed to save`, 'error')
        setLoading(false)
    }
}
const onDeleteColumn = async (column) => {
    try {
        const st = state()
        const {
            session: { user },
            datagrid: { table }
        } = st
        const disabled = user?._id !== table.userId
        if (disabled) {
            toast('You are not authorized to edit this table', 'error')
            return
        }
        console.log('column', column)
        setLoading(true)
        const result = await usertableApi.deleteCell(table._id, { column })
        if (result.status && result.data) {
            dispatch({ type: 'SET_CELLS', payload: result.data })
            dispatch({ type: 'SET_HEADINGS_DG', payload: result.data })
            let c = result.data
            c = c.map((cell) => ({ ...cell, label: titleCase(cell.label) }))
            dispatch({ type: 'SET_CELLS_FOR_DG', payload: c })
            toast('Column deleted successfully', 'success')
        } else {
            toast(`Failed to delete column`, 'error')
        }
        setLoading(false)
    } catch (error) {
        console.log({ error })
        toast(`Failed to delete column`, 'error')
        setLoading(false)
    }
}
const createNewWorkbook = async (type) => {
    const st = state()
    const {
        session: {
            user: { _id }
        },
        datagrid: { table }
    } = st
    const id = table?._id
    if (type === 'clone') {
        try {
            setCreatingNewWb(type)
            const result = await usertableApi.cloneWb(id)
            if (result && result.data && result?.data?.status) {
                toast('Table cloned successfully', 'success')
                window.location = `/dashboard/usertable/${result.data.table._id}`
                setCreatingNewWb(false)
            } else {
                toast(`Failed to clone  Table`, 'error')
                setCreatingNewWb(false)
            }
        } catch (error) {
            toast(`Failed to clone table`, 'error')
            setCreatingNewWb(false)
        }
    } else if (type === 'row') {
        try {
            setCreatingNewWb(type)
            const result = await usertableApi.addWB(id)
            if (result && result.data && result?.data?.status) {
                setCreatingNewWb(false)
                dispatch({ type: 'ADD_ROW_DG', payload: result.data })
            } else {
                toast(`Failed to create new row`, 'error')
                setCreatingNewWb(false)
            }
        } catch (error) {
            const errorData = error?.response?.data || `${error?.response?.status}: ${error?.response?.statusText}`
            toast(`Failed to create new row: ${errorData}`, 'error')
            setCreatingNewWb(false)
        }
    }
}

const fetchRow = async (rowId, cellId = null) => {
    try {
        const id = state().datagrid.table._id
        const result = await usertableApi.getRow(id, rowId)
        if (result.status && result.data && result.data) {
            if (cellId) {
                const oldRow = rows.find((row) => row._id === rowId)
                if (oldRow) {
                    oldRow[cellId] = result.data[cellId]
                    dispatch({ type: 'UPDATE_ROW_DG', payload: oldRow })
                } else {
                    console.log('row not found')
                }
            } else {
                dispatch({ type: 'UPDATE_ROW_DG', payload: result.data })
            }
        }
    } catch (error) {
        const errorData = error?.response?.data || `${error?.response?.status}: ${error?.response?.statusText}`
        toast(`Failed to retrieve row: ${errorData}`, 'error')
    }
}
const deleteRows = async (selectedRows) => {
    const id = state().datagrid.table._id
    try {
        setLoading(true)
        toast('Deleting rows', 'info')
        const result = await usertableApi.updateUsertable(id, { deleteRows: true, ids: selectedRows })
        if (result.status && result.data && result?.data?.status) {
            dispatch({ type: 'DELETE_ROWS_DG', payload: selectedRows })
            toast('Rows deleted successfully', 'success')
        } else {
            toast(`Failed to delete rows`, 'error')
        }
        setLoading(false)
    } catch (error) {
        console.log(error)
        toast(`Failed to delete rows:`, 'error')
        setLoading(false)
    }
}
const runOnSelected = async (rowsIds, cellId, isReRun = false) => {
    try {
        const st = state()
        const { rows, table } = st.datagrid
        const id = table._id
        const { socketId, roomId } = st.socket
        rowsIds = rowsIds.filter((rowId) => {
            const row = rows.find((r) => r._id === rowId)
            if (row) {
                if (isReRun) {
                    return true
                }
                let cellData = row[cellId]
                if (!cellData || cellData === '' || cellData === 'null' || cellData === 'undefined') {
                    return true
                }
                cellData = cellData.toString().toLowerCase().trim()
                if (cellData === 'queued...') {
                    return false
                } else if (cellData === 'processing...') {
                    return false
                } else if (cellData === 'error' || cellData === 'not found') {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        })
        if (rowsIds.length === 0) {
            toast('No row found to run', 'error')
            return
        }
        await usertableApi.getAiColumnData(id, {
            entity: 'tables',
            socketId,
            roomId,
            rowsIds,
            runOnSelectedRows: true,
            cellId
        })
    } catch (e) {
        console.log('error', e)
        const error = 'Failed to run on selected rows'
        toast(error, 'error')
    }
}

const uploadCSV = async (data) => {
    try {
        const st = state()
        const {
            datagrid: {
                table: { _id },
                headings
            }
        } = st
        const { csvData, mappedCols } = data
        const csvDatum = { data: csvData, uploading: false, mappedCols, tableId: _id }
        await handleUploadCSV(csvDatum, headings, _id)
        return false
    } catch (e) {
        console.log(e)
        return false
    }
}
const importCsv = async (data, isChunk = false, id) => {
    try {
        if (isChunk) {
            const result = await usertableApi.importCsv(id, data)
            if (result.status && result.data && result.data.rows) {
                dispatch({ type: 'ADD_ROWS_DG', payload: result.data.rows })
            }
            return true
        }
        setLoading(true)
        const result = await usertableApi.importCsv(id, data)
        if (result.status && result.data && result.data.cells) {
            toast(`CSV imported Successfully`, 'success')
            c = result.data.cells.map((cell) => ({ ...cell, label: titleCase(cell.label) }))
            dispatch({ type: 'ADD_AI_GENERATED_CELLS', payload: c })
            dispatch({ type: 'ADD_ROWS_DG', payload: result.data.rows })
            const cells = [...headings, ...c]
            dispatch({ type: 'SET_CELLS_FOR_DG', payload: cells })
        } else {
            setLoading(false)
            toast(`Failed to import CSV`, 'error')
        }
        return result
    } catch (error) {
        console.log('error', error)
        const errorData = error?.response?.data?.message || `${error?.response?.status}: ${error?.response?.statusText}`
        toast(`Failed to import CSV: ${errorData ?? ''}`, 'error')
        if (isChunk) {
            return false
        }
        return error
    }
}
const handleUploadCSV = async (csv, cols, id) => {
    try {
        setLoading(true)
        const csvData = csv.data
        const mappedCols = csv.mappedCols
        const headers = (csvData[0] ?? []).map((header) => header.toString().trim().toLowerCase())
        const data = csvData.slice(1)
        // make chunks of 100 rows
        const chunks = []
        const colsMap = cols.reduce((acc, curr) => {
            acc[curr.label.toString().trim().toLowerCase()] = curr._id
            return acc
        }, {})
        const headerMap = {}
        headers.map((heading, index) => {
            const label = mappedCols[heading]
            const findLabelId = label ? colsMap[label.toString().trim().toLowerCase()] : null
            headerMap[index] = findLabelId
        })
        const chunkSize = 100000000
        for (let i = 0; i < data.length; i += chunkSize) {
            chunks.push(data.slice(i, i + chunkSize))
        }
        if (data.length === 0) {
            chunks.push([])
        }
        for (let i = 0; i < chunks.length; i++) {
            // append headers to each chunk
            const chunk = chunks[i].map((row) => {
                const newRow = {}
                Object.keys(row).map((key, index) => {
                    const colId = headerMap[index]
                    if (colId) {
                        newRow[colId] = row[key]
                    }
                })
                return newRow
            })
            const isLast = i === chunks.length - 1
            const result = await importCsv({ chunk, isChunk: true, isLast }, true, id)
            if (!result) {
                toast(`Failed to import CSV`, 'error')
                setLoading(false)
                getTable(id)
                return
            }
            if (isLast) {
                toast(`CSV imported successfully`, 'success')
                setLoading(false)
                getTable(id)
            }
        }
    } catch (error) {
        console.log('error', error)
        const errorData = error?.response?.data?.message || `${error?.response?.status}: ${error?.response?.statusText}`
        toast(`Failed to import CSV: ${errorData ?? ''}`, 'error')
        setLoading(false)
    }
}

const startScrape = async (url, prompt, wait = 0) => {
    try {
        const st = state()
        const {
            socket: { roomId, socketId },
            datagrid: {
                table: { _id },
                headings
            }
        } = st
        setLoading(true)
        const result = await apps.startScrape(_id, { url, prompt, roomId, socketId, wait })
        if (result.status && result.data) {
            toast(`Scrape started successfully`, 'success')
        }
        //     dispatch({ type: 'ADD_ROWS_DG', payload: result.data })
        // } else if (result.status && result.data && result.data.chunks) {
        //     setLoading(false)
        //     toast(`Importing data...`, 'info')
        //     getTable()
        // } else {
        //     setLoading(false)
        //     toast(`Failed to start scrape`, 'error')
        // }
        setLoading(false)
        return result
    } catch (error) {
        toast(`Failed to start scrape`, 'error')
        setLoading(false)
        return error
    }
}
const runAiCellOnRowUpdate = async (row, cells) => {
    const doTest = true
    if (doTest) return
    const st = state()
    const {
        datagrid: { headings }
    } = st
    const cellsIds = checkForRunAiCellOnRowUpdate(row, cells ?? headings)
    if (cellsIds && cellsIds.length > 0) {
        cellsIds.forEach((cellId) => {
            dispatch({ type: 'UPDATE_CELL_DATA_DG', payload: { rowId: row._id, cellId: cellId, value: 'Queued...' } })
            runOnSelected([row._id], cellId)
        })
    }
}
const runOnSelectedRows = async (payload) => {
    try {
        const st = state()
        const {
            socket: { roomId, socketId },
            datagrid: {
                table: { _id }
            }
        } = st
        await usertableApi.runOnRows(_id, { payload, socketId, roomId })
        toast('AI cells queued successfully', 'success')
    } catch (error) {
        console.log('error', error)
        toast(`Failed to run on selected rows`, 'error')
    }
}
const runOnSelectedCells = async () => {
    try {
        const st = state()
        const {
            socket: { roomId, socketId },
            datagrid: {
                table: { _id },
                headings,
                highlightedCells
            }
        } = st
        //to do
        const aiCellIds = headings.filter((heading) => heading.type === 'AI' || heading.type === 'Waterfall').map((heading) => heading._id)
        if (aiCellIds.length === 0) {
            toast('No AI cell found', 'error')
            return
        }
        if (highlightedCells.length === 0) {
            toast('No Cell selected', 'error')
            return
        }
        const toRun = []
        let updatedRows = rows
        highlightedCells.forEach((gridid) => {
            const [rowId, cellId] = gridid.split('_')
            if (aiCellIds.includes(cellId)) {
                toRun.push({ rowId, cellId })
                const findRow = updatedRows.find((row) => row._id === rowId)
                if (findRow) {
                    findRow[cellId] = 'Queued...'
                }
            }
        })
        if (toRun.length === 0) {
            toast('No AI cell found to run', 'error')
            return
        }
        dispatch({ type: 'SET_ROWS_DG', payload: updatedRows })
        await usertableApi.runOnRows(_id, { toRun, socketId, roomId })
        toast('AI cells queued successfully', 'success')
    } catch (error) {
        console.log('error', error)
        toast(`Failed to run on selected rows`, 'error')
    }
}
const selectConnection = async (c) => {
    try {
        const st = state()
        const {
            datagrid: { connectionDialog }
        } = st
        connectionDialog
        const { tool, item } = connectionDialog
        tool.connectionId = c._id
        const tools = item.tools ?? []
        item.tools = [...new Set([...tools, tool])]
        const result = await saveCell(item)
        if (result) {
            toast('API key saved successfully', 'success')

            dispatch({ type: 'CLOSE_CONNECTION_DIALOG', payload: null })
            // dispatch({ type: 'CLOSE_CATEGORY_MODAL' })
        } else {
            toast(`Failed to save API key`, 'error')
        }
    } catch (error) {
        console.log('error', error)
        toast(`Failed to save API key`, 'error')
    }
}
const handleDatagridSocketCells = (data) => {
    if (data) {
        const st = state()
        const { table } = st.datagrid
        const msg = data?.message
        if (msg && msg.data && typeof msg.data === 'object' && msg.data.type === 'UPDATE_CELL') {
            const newCells = msg.data.cells
            c = newCells.map((cell) => ({ ...cell, label: titleCase(cell.label) }))
            dispatch({ type: 'ADD_AI_GENERATED_CELLS', payload: c })
            fetchRow(msg.rowId)
        } else if (msg && msg.type && msg.type === 'SCRAPE_STATUS' && msg.tableId === table?._id) {
            if (msg.isCompleted) {
                getTable(table?._id)
            }
        }
    }
}
const handleDatagridSocketGridData = (data) => {
    if (data) {
        const gridData = []
        if (data && isArray(data) && data.length > 0) {
            data.forEach((item) => {
                const msg = item?.message
                if (msg && msg.rowId != undefined && msg.cellId != undefined && msg.dataType && msg.dataType === 'UPDATE_CELL_DATA') {
                    let value =
                        msg.data && typeof msg.data === 'object' && msg.data.gotAnswer && msg.data.answer ? msg.data.answer : msg.data
                    gridData.push({ rowId: msg.rowId, cellId: msg.cellId, value })
                }
            })
            if (gridData.length > 0) {
                dispatch({ type: 'UPDATE_GRID_DATA', payload: gridData })
            }
        }
    }
}
export {
    handleDatagridSocketCells,
    handleDatagridSocketGridData,
    getTable,
    saveRow,
    saveCell,
    handleRename,
    saveOrder,
    onDeleteColumn,
    createNewWorkbook,
    fetchRow,
    deleteRows,
    runOnSelected,
    uploadCSV,
    importCsv,
    startScrape,
    runOnSelectedRows,
    runOnSelectedCells,
    selectConnection,
    runAiCellOnRowUpdate,
    toast,
    handleUploadCSV,
    getCells,
    getRows,
    updateFilterDb
}
